import React, { useState, useEffect } from "react";
import { getUrl, postUrl } from "../utils/utils";
import Button from "react-bootstrap/Button";
import Banners from "./Banners";
import { useNavigate } from "react-router-dom";

export default function Playlists(props) {
  const [rowsPlaylist, setRowsPlaylist] = useState([]);
  const [rowsTemplate, setRowsTemplate] = useState([]);
  const [newValue, setNewValue] = useState("");
  const [template, setTemplate] = useState(
    "17df7ecb34f062605d2e877dbe2dde1f87747c214d691396329d78ec274e21fd"
  );

  useEffect(() => {
    loadPlaylist();
  }, []);

  async function loadPlaylist() {
    let response = await getUrl("/api/playlists");
    if (response.status === 200) {
      let rows = [...response.data];
      rows.sort((a, b) => {
        if (a.name < b.name) {
          return 1;
        } else if (a.name > b.name) {
          return -1;
        }
        return 0;
      });
      let rowsPlaylist1 = [];
      let rowsTemplate1 = [];
      rows.forEach((r) => {
        let n1 = r.name.substring(0, 1);
        if ("0" <= n1 && n1 <= "9") {
          rowsPlaylist1.push(r);
        } else {
          rowsTemplate1.push(r);
        }
      });
      setRowsPlaylist(rowsPlaylist1);
      setRowsTemplate(rowsTemplate1);
    }
  }

  const navigate = useNavigate();
  return (
    <Banners
      breadcrumbs={[
        { name: "Playlists", link: `${process.env.PUBLIC_URL}/playlists` },
      ]}
    >
      <div className="flex-column">
        <div className="flex-row-fixed">
          <input
            name="new"
            value={newValue}
            placeholder="new playlist name"
            onChange={({ currentTarget: input }) => {
              setNewValue(input.value);
            }}
          ></input>
          <select
            name="templates"
            value={template}
            onChange={({ currentTarget: input }) => {
              setTemplate(input.value);
            }}
          >
            {rowsTemplate.map((r) => (
              <option value={r._id} key={r._id}>
                {r.name}
              </option>
            ))}
          </select>

          <Button
            variant="secondary"
            disabled={!newValue}
            onClick={async () => {
              let items = [{ name: "blank", type: "blank", url: "" }];
              for (let i = 0; i < rowsTemplate.length; i++) {
                let r = rowsTemplate[i];
                if (r._id === template) {
                  items = (await getUrl("/api/playlists/" + r._id)).data.items;
                }
              }
              await postUrl("/api/playlists", {
                name: newValue,
                items,
              });

              setNewValue("");
              loadPlaylist();
            }}
          >
            Create
          </Button>
        </div>

        <div className="flex-row-fixed flex-wrap">
          {rowsPlaylist.map((row) => (
            <Button
              key={row._id}
              className="m-1"
              variant="secondary"
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/playlists/` + row._id);
              }}
            >
              {row.name}
            </Button>
          ))}
          {rowsTemplate.map((row) => (
            <Button
              key={row._id}
              className="m-1"
              style={{ background: "#ffffff", color: "#222222" }}
              variant="secondary"
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/playlists/` + row._id);
              }}
            >
              {row.name}
            </Button>
          ))}
        </div>
      </div>
    </Banners>
  );
}
