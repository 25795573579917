import React from "react";
import Button from "react-bootstrap/Button";
import Banners from "./Banners";
import { useNavigate } from "react-router-dom";

export default function NotFound(props) {
  const navigate = useNavigate();

  return (
    <div className="flex-column">
      <Banners breadcrumbs={[]} />
      <br />
      <h1>Not Found</h1>
      <div className="flex-row-fixed">
        <Button
          variant="secondary"
          onClick={(e) => {
            e.preventDefault();
            navigate(`${process.env.PUBLIC_URL}/`);
          }}
        >
          Go to Home
        </Button>
      </div>
    </div>
  );
}
