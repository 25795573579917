import React from "react";

export default function Tab({ activeTab, label, display, onClick }) {
  let className = display + " tab-list-item";

  if (activeTab === label) {
    className += " tab-list-active";
  }

  return (
    <li className={className} onClick={() => onClick(label)}>
      {label}
    </li>
  );
}
