import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

let instance = null;

export default class Dialog extends Component {
  state = { dialogs: [] };

  componentDidMount() {
    instance = this;
  }

  handleClose = (button) => {
    let removedDialog = this.state.dialogs[this.state.dialogs.length - 1];
    removedDialog.action = button;
    let remainingDialogs = this.state.dialogs.slice(
      0,
      this.state.dialogs.lnegth - 1
    );
    let resolve = this.state.dialogs[this.state.dialogs.length - 1].resolve;
    this.setState({ dialogs: remainingDialogs });
    resolve(removedDialog);
  };

  static showDialog = (title, funcBody, buttons) => {
    return new Promise((resolve, reject) => {
      instance.setState({
        dialogs: [
          ...instance.state.dialogs,
          { title, funcBody, buttons, resolve, reject },
        ],
      });
    });
  };

  static showConfirm = (title, message) => {
    return new Promise((resolve1, reject) => {
      instance.setState({
        dialogs: [
          ...instance.state.dialogs,
          {
            title,
            funcBody: () => <div className="flex-column m-2">{message}</div>,
            buttons: ["Yes", "No"],
            resolve: (r) => resolve1(r.action),
            reject,
          },
        ],
      });
    });
  };

  static showMessage = (title, message) => {
    return new Promise((resolve1, reject) => {
      instance.setState({
        dialogs: [
          ...instance.state.dialogs,
          {
            title,
            funcBody: () => <div className="flex-column m-2">{message}</div>,
            buttons: ["Ok"],
            resolve: (r) => resolve1(r.action),
            reject,
          },
        ],
      });
    });
  };

  static showPrompt = (title, label, value) => {
    return new Promise((resolve1, reject) => {
      let buttons = ["Ok", "Cancel"];
      let funcBody = (values, setValues) => (
        <div className="flex-column m-2">
          <div>{label}</div>
          <input
            value={values.value}
            onChange={({ currentTarget: input }) => {
              let v = { ...values };
              v.value = input.value;
              setValues(v);
            }}
          />
        </div>
      );
      instance.setState({
        dialogs: [
          ...instance.state.dialogs,
          {
            title,
            funcBody,
            buttons,
            values: { value },
            resolve: (r) => {
              if (r.action === "Ok") {
                resolve1(r.values.value);
              } else {
                resolve1(null);
              }
            },
            reject,
          },
        ],
      });
    });
  };

  render() {
    return this.state.dialogs.map((dialog, index) => (
      <React.Fragment>
        <Modal
          key={index}
          size="xl"
          show={true}
          backdrop="static"
          onHide={() => this.handleClose(null)}
          style={{ width: "100%", height: "100%" }}
        >
          {dialog.title && (
            <Modal.Header closeButton>
              <Modal.Title>{dialog.title}</Modal.Title>
            </Modal.Header>
          )}
          {dialog.funcBody(dialog.values, (values) => {
            let dialogs = [...this.state.dialogs];
            dialogs[index] = { ...dialogs[index] };
            dialogs[index].values = { ...values };
            this.setState({ dialogs });
          })}
          {dialog.buttons && (
            <Modal.Footer>
              {dialog.buttons.map((button, index) => (
                <Button
                  key={index}
                  variant="secondary"
                  onClick={() => this.handleClose(button)}
                >
                  {button}
                </Button>
              ))}
            </Modal.Footer>
          )}
        </Modal>
      </React.Fragment>
    ));
  }
}
