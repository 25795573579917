import React, { Component } from "react";

import poster1 from "../resources/loops/Background_Blue_Light.jpg";
import poster2 from "../resources/loops/background_Blue_Smoky_Motion_Loop.jpg";
import poster3 from "../resources/loops/background_Glowing_Strokes_-_Golden.jpg";
import poster4 from "../resources/loops/background_Glowing_Strokes_-_Purple.jpg";
import poster5 from "../resources/loops/background_Gold_Flakes.jpg";
import poster6 from "../resources/loops/background_Mosaic_Motion_Loops.jpg";
import poster7 from "../resources/loops/background_Pink.jpg";
import poster8 from "../resources/loops/background_Polygon_Loop.jpg";
import poster9 from "../resources/loops/Background_Aqua.jpg";

import Config from "../utils/config";

let posters = [
  poster1,
  poster2,
  poster3,
  poster4,
  poster5,
  poster6,
  poster7,
  poster8,
  poster9,
];
let loops = [
  "/3940892c426e50a712ca7c158f4fe812ab767b729c0fd6919fe65299c95900e7/Background_Blue_Light.mp4",
  "/a92f596b012081eed4b6a96aefb595712dfd546dcc7afe41585d29818143f501/background_Blue_Smoky_Motion_Loop.mp4",
  "/56fb0add246e307d2b1471c2b64149f727c50e9fcf23be93e1ce6abc3fe7a2d9/background_Glowing_Strokes_-_Golden.mp4",
  "/4657f294d94a8995149a6a9375f7471945b4b0ee1f07d20cfe2b7deb9def0366/background_Glowing_Strokes_-_Purple.mp4",
  "/64b1631fb6e41064e6751cffa14ac6b70ae9a371f4d43e911648d3c6dca0f722/background_Gold_Flakes.mp4",
  "/2fb6af32dd1b359aef19f80b070d5b12e6d6648934b91d1b5267e1c78c7ea4e5/background_Mosaic_Motion_Loops.mp4",
  "/4eab7f54787f98cbf5613515e781c1bfeb7cb981da2d4b2d282bfdf5d50d6e69/background_Pink.mp4",
  "/36df7c4ede8cb73879b75b7631f55de2a933dd0ad6e8622ec34887cabf1ae19e/background_Polygon_Loop.mp4",
  "/392a4512da2fa732ece5e32a2152aaac7b3e3b10ad0ea2c330e53b695e6a59bc/Background_Aqua.mp4",
];

export default class LyricsSlide extends Component {
  state = {
    data: { type: "video", controls: true, url: "./adventistlogo.m4v" },
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          fontSize: "75px",
          fontFamily: "Helvetica",
          color: "#FFF",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: 999,
          justifyContent: "center",
        }}
      >
        {this.renderImage()}
        <div
          style={{
            display: "flex",
            position: "absolute",
            fontSize: "75px",
            fontFamily: "Helvetica",
            color: "#FFF",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            zIndex: 999,
          }}
        >
          {this.renderSwg()}
        </div>
      </div>
    );
  }

  renderImage() {
    let background = this.props.slide.background;
    let url = null;
    let urlPoster = null;
    if (!isNaN(background)) {
      url =
        Config.getMediaDownloadUrl() +
        encodeURI(loops[background % loops.length]);
      urlPoster = posters[background % posters.length];
    } else {
      url = Config.getMediaDownloadUrl() + encodeURI(background);
      let index = loops.findIndex((loop) => loop === background);
      if (index !== -1) {
        urlPoster = posters[index];
      }
    }
    if (urlPoster && this.props.posterOnly) {
      url = "";
    }
    return (
      <video
        key={background}
        poster={urlPoster}
        autoPlay={this.props.type !== "preview"}
        controls={false}
        loop={true}
        muted={true}
        id="video"
        style={{ width: "100%", height: "100%", justifyContent: "center" }}
      >
        <source id="videosource" key={url} src={url} type="video/mp4" />
      </video>
    );
  }

  renderSwg() {
    let slide = [
      this.props.slide.Copyright, //keep in index 0 (see a few lines below)
      this.props.slide.Body, //keep in index 1 (see a few lines below)
      this.props.slide.Title,
    ];
    let width = this.props.width || "100%";
    let height = this.props.height || "100%";
    return (
      <svg width={width} height={height} viewBox="0 0 1920 1080">
        {slide.map((element, index2) => {
          let elementHeight = element.Height;
          if (index2 === 1) {
            //Body (see a few lines above)
            if (
              elementHeight + element.Y > this.props.slide.Copyright.Y &&
              (this.props.slide.Copyright.text.length === 0 ||
                this.props.slide.Copyright.text[0])
            ) {
              elementHeight = this.props.slide.Copyright.Y - element.Y;
            }
          }
          let fontSize = element.FontSize * 1.5;
          let lineHeight = elementHeight / element.text.length;
          if (lineHeight > fontSize * 1.3) {
            lineHeight = fontSize * 1.3;
          }
          let adjustY = (elementHeight - lineHeight * element.text.length) / 2;
          if (adjustY < 0) {
            adjustY = 0;
          }

          return element.text.map((text, index) => {
            let ret = [];
            for (let xy = element.Shadow; xy <= element.Shadow * 3; xy++) {
              ret.push(
                <text
                  key={xy}
                  x={element.X + element.Width / 2 + xy}
                  y={adjustY + element.Y + lineHeight * index + xy}
                  dominantBaseline="hanging"
                  textAnchor="middle"
                  fill="black"
                  fontSize={fontSize}
                  fontFamily="Vera"
                  fontWeight="bold"
                  opacity={0.1}
                >
                  {text}
                </text>
              );
            }
            for (let x = -element.Shadow; x <= element.Shadow; x++) {
              for (let y = -element.Shadow; y <= element.Shadow; y++) {
                ret.push(
                  <text
                    key={x + " " + y}
                    x={element.X + element.Width / 2 + x}
                    y={adjustY + element.Y + lineHeight * index + y}
                    dominantBaseline="hanging"
                    textAnchor="middle"
                    fill="black"
                    fontSize={fontSize}
                    fontFamily="Vera"
                    fontWeight="bold"
                  >
                    {text}
                  </text>
                );
              }
            }
            ret.push(
              <text
                key={index2}
                x={element.X + element.Width / 2}
                y={adjustY + element.Y + lineHeight * index}
                dominantBaseline="hanging"
                textAnchor="middle"
                fill={element.FontColor}
                fontSize={fontSize}
                fontFamily="Vera"
                fontWeight="bold"
              >
                {text}
              </text>
            );
            return ret;
          });
        })}
      </svg>
    );
  }
}
