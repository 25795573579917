import React, { useState } from "react";

export default function List(props) {
  const [selectedIndexFrom, setSelectedIndexFrom] = useState(0);
  const [selectedIndexTo, setSelectedIndexTo] = useState(0);

  let lastClick = 0;

  function onSelect(from, to) {
    if (props.disabled) {
      return;
    }
    const count = props.children.length;
    if (from < 0 || to < 0 || from >= count || to >= count) {
      return;
    }

    setSelectedIndexFrom(from);
    setSelectedIndexTo(to);

    if (props.onSelect) {
      props.onSelect(from, to);
    }
  }

  function onExecute(index) {
    if (index === null) {
      if (props.onExecute) {
        props.onExecute(-1);
      }
      return;
    }

    if (index < 0 || index >= props.children.length) {
      return;
    }
    if (index === props.executeIndex) {
      return;
    }
    if (
      props.items &&
      props.items.length &&
      localStorage.getItem("playing_id") === props.items[selectedIndexFrom]._id
    ) {
      onSelect(index, index);
    }
    if (props.onExecute) {
      props.onExecute(index);
    }
  }

  lastClick = 0;

  function renderChildren(children, index) {
    let style = {
      overflow: "hidden",
    };

    return (
      <React.Fragment key={index}>
        <div
          id={"list1_" + index}
          className="flex-column-fixed"
          onClick={(event) => {
            let now = Date.now();
            onSelect(event.shiftKey ? selectedIndexFrom : index, index);
            if (lastClick + 500 >= now) {
              lastClick = now;
              if (index === props.executeIndex) {
                onExecute(null);
              } else {
                onExecute(index);
              }
            }
            lastClick = now;
          }}
          style={style}
        >
          {children.map((child, index2) => (
            <div key={index + index2} className="flex-column-fixed">
              {child}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }

  let childGroups = [];
  let childGroupsIndex = [];
  {
    let priorSelected = false;
    props.children.forEach((child, index) => {
      if (!priorSelected) {
        childGroups.push([child]);
        childGroupsIndex.push(index);
        if (index === selectedIndexFrom) {
          priorSelected = true;
        }
      } else if (index > selectedIndexTo) {
        priorSelected = false;
        childGroups.push([child]);
        childGroupsIndex.push(index);
      } else {
        childGroups[childGroups.length - 1].push(child);
      }
    });
  }

  let index = props.selectedIndexFrom;
  return (
    <div
      id="list1"
      className="flex-column-fixed"
      style={{ opacity: props.disabled ? "50%" : "100%" }}
      tabIndex="0"
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          onExecute(index);
        } else if (event.keyCode === 37) {
          //left arrow
          if (props.items) {
            let firstPlaying = -1;
            props.items.forEach((item, index) => {
              if (localStorage.getItem("playing_id") === item._id) {
                firstPlaying = index;
              }
            });
            onExecute(firstPlaying - 1);
          }
        } else if (event.keyCode === 38) {
          //up arrow
          onSelect(selectedIndexFrom - 1, selectedIndexFrom - 1);
          event.preventDefault();
        } else if (event.keyCode === 39) {
          //right arrow
          if (props.items) {
            let firstPlaying = -2;
            props.items.forEach((item, index) => {
              if (localStorage.getItem("playing_id") === item._id) {
                firstPlaying = index;
              }
            });
            onExecute(firstPlaying + 1);
          }
        } else if (event.keyCode === 40) {
          //down arrow
          onSelect(selectedIndexFrom + 1, selectedIndexFrom + 1);
          event.preventDefault();
        } else if (event.keyCode === 66) {
          //B
          if (index === props.executeIndex) {
            onExecute(null);
          } else {
            onExecute(index);
          }
        }
      }}
    >
      {childGroups.map((children, index) =>
        renderChildren(children, childGroupsIndex[index])
      )}
    </div>
  );
}
