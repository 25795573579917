import React, { Component } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Playlists from "./components/Playlists";
import VideostreamingVm from "./components/VideostreamingVm";
import QueuePage from "./components/QueuePage";
import QueuePageReadOnly from "./components/QueuePageReadOnly";
import Attachments from "./components/attachments/Attachments";
import Upload from "./components/Upload";
import Lyrics from "./components/Lyrics";
import Settings from "./components/Settings";
import Screen from "./components/Screen";
import LoginForm from "./components/LoginForm";
import LogoutForm from "./components/LogoutForm";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import WebSocket from "./components/WebSocket";
import { Route, Navigate, Routes } from "react-router-dom";
import authService from "./utils/authService";
import Dialog from "./components/Dialog";

export default class App extends Component {
  state = {
    errorInfo: "",
  };

  async componentDidCatch(error, info) {
    const errorInfo = {
      error: error && error.toString(),
      stack: error && error.stack,
      info: info && info.componentStack && info.componentStack.toString(),
      href: window.location.href,
    };

    this.setState({
      errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      let errorInfo = JSON.stringify(this.state.errorInfo, null, 4);
      errorInfo = errorInfo.split("\\n");
      return (
        <React.Fragment>
          <a href="/">Reload Page</a>
          <hr />
          {errorInfo.map((e) => (
            <React.Fragment>
              {e}
              <br />
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <WebSocket />
        <Dialog />
        {authService.getProfile() && (
          <Routes>
            <Route
              path={`${process.env.PUBLIC_URL}/playlists/:id`}
              element={<QueuePage />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/presentations/:id`}
              element={<QueuePage type="presentation" />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/playlists`}
              element={<Playlists />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/videostreamingvm`}
              element={<VideostreamingVm />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/media`}
              element={<Attachments />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/upload`}
              element={
                <Attachments
                  name="attachments"
                  uploadOnly={true}
                  label="label"
                  error="error"
                  readOnly={false}
                  setRef={undefined}
                />
              }
            />
            <Route
              path={`${process.env.PUBLIC_URL}/lyrics`}
              element={<Lyrics />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/settings`}
              element={<Settings />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/screen-novideo`}
              element={<Screen type="novideo" />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/screen-stage`}
              element={<Screen type="stage" />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/screen-subtitles`}
              element={<Screen type="subtitles" />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/screen`}
              element={<Screen type="screen" />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/screen-notes`}
              element={<Screen type="notes" />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/logout`}
              element={<LogoutForm />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/index.html`}
              element={<Home />}
            />
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
            <Route
              path={`${process.env.PUBLIC_URL}/*`}
              element={<NotFound />}
            />
          </Routes>
        )}
        {!authService.getProfile() && (
          <Routes>
            {" "}
            <Route
              path={`${process.env.PUBLIC_URL}/screen-novideo`}
              element={<Screen type="novideo" />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/upload`}
              element={<Upload />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/login`}
              element={<LoginForm />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/playlists/:id`}
              element={<QueuePageReadOnly />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/lyrics`}
              element={<Lyrics readonly={true} />}
            />
            <Route
              path="/index.html"
              element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />}
            />
            <Route
              path="/"
              element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />}
            />
          </Routes>
        )}
      </React.Fragment>
    );
  }
}
