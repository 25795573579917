import React from "react";
import Attachments from "./attachments/Attachments";

export default function Upload(props) {
  return (
    <Attachments
      name="attachments"
      uploadOnly={true}
      history={props.history}
      label="label"
      error="error"
      readOnly={false}
      setRef={undefined}
      path={props.path}
      onSelect={props.onSelect}
      onExecute={props.onExecute}
      onAdd={props.onAdd}
      playlistName={props.playlistName}
    />
  );
}
