import React, { Component } from "react";
import axios from "axios";
import WebSocket from "./WebSocket";
import { v4 as uuidv4 } from "uuid";
import ScreenSelection, { getDefaultScreens } from "./ScreenSelection";

export default class Bible extends Component {
  state = {
    books: [],
    verses: [],
    data: {
      translation: "kjv",
      reference: "",
      content: [],
      screens: getDefaultScreens(),
    },
  };

  async componentDidMount() {
    this.loadBible();
  }

  async loadBible() {
    const bible = (
      await axios.get("/bible/" + this.state.data.translation + ".json")
    ).data;
    this.setState(
      {
        books: bible.books,
        verses: bible.verses,
      },
      () => {
        this.search(this.state.data.reference);
      }
    );
  }

  search(search) {
    let ret = "";
    let data = { ...this.state.data };

    let bookIndex = -1;
    let chapterIndex = -1;
    let verseIndex = -1;
    let listBooks = this.state.books.filter((b) => {
      b = b.toLowerCase();
      let s = search.toLowerCase();
      if (
        b.length >= 2 &&
        s.length >= 2 &&
        b.substring(1, 2) === " " &&
        s.substring(1, 2) === " "
      ) {
        b = b.substring(0, 1) + b.substring(2);
        s = s.substring(0, 1) + s.substring(2);
      }
      return b.startsWith(s.split(" ")[0]);
    });
    if (listBooks.length === 1) {
      this.state.books.forEach((book, index) => {
        if (book === listBooks[0]) {
          bookIndex = index;
        }
      });
    }
    if (bookIndex >= 0 && search.indexOf(" ", 2) !== -1) {
      let chapterVerse = search.substring(search.indexOf(" ", 2) + 1);
      if (chapterVerse.indexOf(":") >= 0) {
        chapterVerse = chapterVerse.split(":");
        chapterIndex = parseInt(chapterVerse[0]);
        if (isNaN(chapterIndex)) {
          chapterIndex = -1;
        } else {
          chapterIndex--;
          verseIndex = parseInt(chapterVerse[1]);
          if (isNaN(verseIndex)) {
            verseIndex = -1;
          } else {
            verseIndex--;
          }
        }
      }
    }

    if (bookIndex >= 0 && chapterIndex >= this.state.verses[bookIndex].length) {
      chapterIndex = -1;
      verseIndex = -1;
    } else if (
      chapterIndex >= 0 &&
      verseIndex >= this.state.verses[bookIndex][chapterIndex].length
    ) {
      verseIndex = -1;
    }

    if (verseIndex >= 0) {
      data.content = [];
      for (let i = verseIndex - 4; i < verseIndex + 5; i++) {
        if (i < 0 || i >= this.state.verses[bookIndex][chapterIndex].length) {
          data.content.push("");
        } else {
          let line =
            "(" +
            this.state.books[bookIndex] +
            " " +
            (chapterIndex + 1) +
            ":" +
            (i + 1) +
            ") " +
            this.state.verses[bookIndex][chapterIndex][i];
          data.content.push(line);
          if (i === verseIndex) {
            ret = this.state.verses[bookIndex][chapterIndex][i];
          }
        }
      }
    } else if (chapterIndex >= 0) {
      data.content = Object.keys(
        this.state.verses[bookIndex][chapterIndex]
      ).map(
        (v) =>
          this.state.books[bookIndex] +
          " " +
          (chapterIndex + 1) +
          ":" +
          (parseInt(v) + 1)
      );
    } else if (bookIndex >= 0) {
      data.content = Object.keys(this.state.verses[bookIndex]).map(
        (c) => this.state.books[bookIndex] + " " + (parseInt(c) + 1)
      );
    } else {
      data.content = [...listBooks];
    }
    data.reference = search;
    this.setState({ data });
    return ret;
  }

  renderTranslation() {
    return (
      <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "row" }}>
        <select
          value={this.state.data.translation}
          onChange={({ currentTarget: input }) => {
            let data = { ...this.state.data, translation: input.value };
            this.setState({ data }, () => {
              this.loadBible();
            });
          }}
        >
          <option value="asv">asv</option>
          <option value="bbe">bbe</option>
          <option value="kjv">kjv</option>
          <option value="web">web</option>
          <option value="ylt">ylt</option>
        </select>
        <button
          onClick={() => {
            const data = {
              content: "",
              name: "",
              reference: "",
              translation: this.state.data.translatio,
              type: "bible",
              _id: uuidv4(),
              screens: this.state.data.screens,
            };
            WebSocket.emit("subtitles", data, this.props.playlistitems);
            let screens = this.state.data.screens
              .filter((screen) => screen.enabled)
              .map((screen) => screen.id);
            if (
              screens.includes("novideo") ||
              screens.includes("screen") ||
              screens.includes("stage")
            ) {
              WebSocket.emit("play", data, this.props.playlistitems);
            }
          }}
        >
          Clear Verse
        </button>
        <ScreenSelection
          screens={this.state.data.screens}
          onChange={(screens) => {
            let data = { ...this.state.data };
            data.screens = screens;
            this.setState({ data });
          }}
        />
      </div>
    );
  }

  renderSearchAndButtons() {
    return (
      <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "row" }}>
        <input
          disabled={this.state.edit}
          name="search"
          value={this.state.data.reference}
          onChange={({ currentTarget: input }) => {
            this.search(input.value);
          }}
          placeholder="Search"
          style={{ flex: "1 1 auto" }}
        />

        <button
          disabled={!this.state.data.content}
          onClick={async () => {
            if (this.props.onAdd) {
              this.props.onAdd(this.state.data);
            }
          }}
        >
          Add to Playlist
        </button>
      </div>
    );
  }

  renderTextbox() {
    let items = this.state.data.content;
    return (
      <select
        name="verses"
        size="11"
        onDoubleClick={({ currentTarget: input }) => {
          if (input.value.indexOf(" ") !== -1) {
            const data = {
              content: this.search(input.value),
              name: input.value,
              reference: input.value,
              translation: this.state.data.translation,
              type: "bible",
              _id: uuidv4(),
              screens: this.state.data.screens,
            };
            if (data.content) {
              WebSocket.emit("subtitles", data, this.props.playlistitems);
            }
            let screens = this.state.data.screens
              .filter((screen) => screen.enabled)
              .map((screen) => screen.id);
            if (
              screens.includes("novideo") ||
              screens.includes("screen") ||
              screens.includes("stage")
            ) {
              WebSocket.emit("play", data, this.props.playlistitems);
            }
          }
        }}
      >
        {items.map((item, index) => {
          let value;
          if (item.startsWith("(")) {
            value = item.substring(1, item.indexOf(")"));
          } else if (item.indexOf(" ", 2) === -1) {
            value = item + " ";
          } else if (item.indexOf(":") === -1) {
            value = item + ":";
          } else {
            value = item;
          }
          return (
            <option
              style={{
                color:
                  index === 4 && item.startsWith("(") ? "#FF0000" : "#000000",
              }}
              value={value}
              key={index}
            >
              {item}
            </option>
          );
        })}
      </select>
    );
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
          overflow: "auto",
        }}
      >
        {this.renderTranslation()}
        {this.renderSearchAndButtons()}
        {this.renderTextbox()}
      </div>
    );
  }
}
