import React, { useState } from "react";

export default function Website(props) {
  const [data, setData] = useState({
    name: "",
    url: "",
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflow: "auto",
      }}
    >
      Name:{" "}
      <input
        type="text"
        value={data.name}
        onChange={({ currentTarget: input }) => {
          let data1 = { ...data };
          data1.name = input.value;
          setData(data1);
        }}
      />
      URL:{" "}
      <input
        type="text"
        value={data.url}
        onChange={({ currentTarget: input }) => {
          let data1 = { ...data };
          data1.url = input.value;
          setData(data1);
        }}
      />
      <button
        onClick={() => {
          props.onAdd && props.onAdd(data);
        }}
      >
        Add to Playlist
      </button>
    </div>
  );
}
