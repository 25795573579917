import React from "react";
import VideoControl from "./VideoControl";
import Config from "../../utils/config";
import Mime from "mime";

export default function Control(props) {
  let { name, type, path, _id, isChild } = props.item;
  return (
    <div ref={props.ref1} style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          flex: "1 1 auto",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {isChild && <span>&nbsp;&nbsp;&nbsp;</span>}
        {props.children || "content missing"}
      </div>
      {type === "video" && (
        <VideoControl
          name={name}
          showControls={localStorage.getItem("playing_id") === _id}
        ></VideoControl>
      )}
      {type === "media" &&
      Mime.getType(path) &&
      Mime.getType(path).startsWith("video/") ? (
        <VideoControl
          name={name}
          showControls={localStorage.getItem("playing_id") === _id}
        ></VideoControl>
      ) : props.activeTab === "Presentation" &&
        Mime.getType(path) &&
        Mime.getType(path).startsWith("image/") ? (
        <img
          src={Config.getHost() + "/api/media" + path}
          style={{ width: "100%", opacity: "70%" }}
          alt={name}
        />
      ) : (
        ""
      )}
    </div>
  );
}
