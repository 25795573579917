import React, { Component } from "react";
import { putUrl } from "../utils/utils";
import List from "./controls/List";
import Queue from "./Queue";

export default class History extends Component {
  state = {
    selectedIndex: 0,
    isSelected: {},
  };

  render() {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <List
          disabled={false}
          multiSelect={false}
          onSelect={(selectedIndex, from, to) => {
            let history = [...this.props.items];
            for (let i = 0; i < history.length; i++) {
              history[i].isSelected = selectedIndex === i;
            }
            this.setState({ history, selectedIndex });
          }}
        >
          {this.props.items &&
            this.props.items.map((item) => {
              return (
                <div
                  key={item._id}
                  style={{
                    flex: "0 0 auto",
                    backgroundColor: item.isSelected ? "#CCCCCC" : "#FFFFFF",
                  }}
                >
                  {new Date(item.historyModified).toLocaleString("en", options)}
                </div>
              );
            })}
        </List>
        <hr />
        {this.props.items.length > 0 && (
          <React.Fragment>
            {this.state.selectedIndex > 0 && (
              <button
                onClick={() => {
                  const etag = this.props.items[0]._etag;
                  let data = {
                    ...this.props.items[this.state.selectedIndex],
                    _etag: etag,
                  };
                  Object.keys(data).forEach((fieldname) => {
                    if (fieldname.startsWith("history")) {
                      delete data[fieldname];
                    }
                  });
                  putUrl("/api/playlists/" + this.props.id, data)
                    .then(() => {
                      let href = window.location.href;
                      if (href.indexOf("?") !== -1) {
                        href = href.substring(0, href.indexOf("?"));
                      }
                      href += "?tab=History";
                      window.location.href = href;
                    })
                    .catch((ex) => {
                      alert(
                        "Save failed: " +
                          ex +
                          ". Please try the operation again."
                      );
                    });
                }}
              >
                Restore
              </button>
            )}
            <Queue
              items={this.props.items[this.state.selectedIndex].items}
              activeTab={true}
              readonly={true}
              setItems={() => {}}
              isSelected={this.state.isSelected}
              setIsSelected={(selected) =>
                this.setState({ isSelected: selected })
              }
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
