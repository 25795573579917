import React, { useState, useEffect } from "react";
import WebSocket from "../WebSocket";

export default function VideoControl(props) {
  const [timePos, setTimePos] = useState(0);
  const [timeDuration, setTimeDuration] = useState(0);

  useEffect(() => {
    WebSocket.on("timeupdate", (msg) => {
      msg = JSON.parse(msg);
      setTimePos(msg.pos);
      setTimeDuration(msg.duration);
    });
  });

  function formatTimestamp(value) {
    let seconds = Math.floor(value);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;
    return (
      (hours < 10 ? "0" : "") +
      parseInt(hours) +
      ":" +
      (minutes < 10 ? "0" : "") +
      parseInt(minutes) +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds
    );
  }

  return props.showControls ? (
    <React.Fragment>
      <div>
        {formatTimestamp(timePos) + " / " + formatTimestamp(timeDuration)}
      </div>

      <input
        type="range"
        id="volume"
        name="volume"
        min="0"
        max={timeDuration}
        value={timePos}
        onChange={(event) => {
          WebSocket.emit("seek", { pos: parseInt(event.target.value) });
        }}
        step="1"
        style={{ width: "100%" }}
      ></input>
      <br />
    </React.Fragment>
  ) : null;
}
