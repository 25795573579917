import React, { Component } from "react";
import { getUrl } from "../utils/utils";
import Queue from "./Queue";
import Screen from "./Screen";
import Tabs from "./controls/Tabs";
import Banners from "./Banners";
import config from "../utils/config";
import WebSocket from "./WebSocket";

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default class QueuePageReadOnly extends Component {
  state = {
    playlistName: undefined,
    playlistEtag: undefined,
    mediaPath: "/",
    items: [],
    isMediaDownloaded: [],
    activeTab: getParameterByName("tab") || "Preview",
    isSelected: {},
  };

  setPlaylist = (id) => {
    this.setState({ playlist: id });
    this.props.history.push(`${process.env.PUBLIC_URL}/playlists/` + id);
  };

  async reloadPlaylist() {
    let playlistResponse = await getUrl(
      "/api/playlists/" + this.props.match.params.id
    );
    if (playlistResponse.status === 200) {
      this.setState({
        playlistName: playlistResponse.data.name,
        playlistEtag: playlistResponse.data._etag,
        items: playlistResponse.data.items,
      });
      let breadcrumbs = [
        {
          name: "Playlists",
          link: `${process.env.PUBLIC_URL}/playlists`,
        },
        {
          name: playlistResponse.data.name,
          link:
            `${process.env.PUBLIC_URL}/playlists/` + this.props.match.params.id,
          _id: this.props.match.params.id,
          editField: "name",
          editLabel: "Edit Playlist",
        },
      ];
      Banners.setBreadCrumbs(breadcrumbs);
    }
    if (config.isElectron()) {
      let isMediaDownloaded = {};
      const playlistItems = playlistResponse.data.items;
      playlistItems.forEach((item) => {
        if (item.type === "media") {
          let hash = item.path;
          if (hash.startsWith("/")) {
            let pos = hash.indexOf("/", 1);
            if (pos === -1) {
              hash = hash.substring(1);
            } else {
              hash = hash.substring(1, pos);
            }
          }
          isMediaDownloaded[hash] = false;
        }
      });

      const mediaResponse = await getUrl("http://localhost:8199/api/media");
      if (mediaResponse.status === 200) {
        mediaResponse.data.forEach((item) => {
          if (item.hash in isMediaDownloaded) {
            isMediaDownloaded[item.hash] = true;
          }
        });
        this.setState({ isMediaDownloaded });
      }
    }
  }

  async componentDidMount() {
    await this.reloadPlaylist();
    WebSocket.on("playlistupdate", (msg) => {
      const obj = JSON.parse(msg);
      if (obj._id === this.props.match.params.id) {
        this.reloadPlaylist(obj);
      }
    });
  }

  setItems = async (items, savePlaylist = true) => {};

  render() {
    return (
      <div className="flex-row">
        <Queue
          readonly={true}
          display={
            this.state.activeTab === "Song Sheets"
              ? "d-none d-lg-inline"
              : "d-none d-lg-inline d-print-inline"
          }
          items={this.state.items}
          setItems={this.setItems}
          setme={(me) => {
            this.setState({ queue: me });
          }}
          activeTab={this.state.activeTab}
          isSelected={this.state.isSelected}
          setIsSelected={(selected) => this.setState({ isSelected: selected })}
        />
        <div
          style={{
            flex: "1 1 70%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <Tabs activeTab={this.state.activeTab}>
            <div
              label="Playlist"
              display="d-none d-sm-inline d-lg-none d-print-none"
            >
              <Queue
                display="d-sm-inline"
                items={this.state.items}
                setItems={this.setItems}
                setme={(me) => {
                  this.setState({ queue: me });
                }}
                activeTab={this.state.activeTab}
                isSelected={this.state.isSelected}
                setIsSelected={(selected) =>
                  this.setState({ isSelected: selected })
                }
              />
            </div>
            <div label="Preview" display="d-none d-sm-inline d-print-none">
              <Screen type="preview" />
            </div>
          </Tabs>
        </div>
      </div>
    );
  }
}
