/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer, 2019-2021
 */

import { Component } from "react";
import authService from "../utils/authService";

export default class LogoutForm extends Component {
  render() {
    authService.logout();
    window.location.reload(true);
    window.location = `${process.env.PUBLIC_URL}/`;
    return null;
  }
}
