import React, { useState, useEffect } from "react";
import config from "../utils/config";
import { getUrl } from "../utils/utils";
import Banners from "./Banners";
import { useNavigate } from "react-router-dom";
import gears from "./icons/gears.jpg";
import cardList from "./icons/card-list.svg";
import server from "./icons/server.svg";

export default function Home(props) {
  const [serverbuild, setServerbuild] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.location.href.startsWith("http://localhost:")) {
      getUrl("/siteworship.build").then((response) => {
        let serverbuild1 = response.data;
        const posNewline = serverbuild1.indexOf("\n");
        if (posNewline >= 0) {
          serverbuild1 = serverbuild1.substring(0, posNewline);
        }
        setServerbuild(serverbuild1);
      });
    }
  }, []);

  return (
    <div className="flex-column">
      <Banners breadcrumbs={[]}>
        <div className="LinkButtonContainer">
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate(`${process.env.PUBLIC_URL}/playlists`);
            }}
            className={"LinkButton"}
          >
            <img src={cardList} alt="" />
            <label>Playlists</label>
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate(`${process.env.PUBLIC_URL}/settings`);
            }}
            className={"LinkButton"}
          >
            <img src={gears} alt="" />
            <label>Settings</label>
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate(`${process.env.PUBLIC_URL}/videostreamingvm`);
            }}
            className={"LinkButton"}
          >
            <img
              src={server}
              alt=""
              style={{ width: "60px", height: "60px" }}
            />
            <label>Reserve Streaming Server</label>
          </button>
        </div>
        <div className="flex-row-fixed">&nbsp;</div>
        <div className="flex-row"></div>
        <div className="flex-row-fixed build-text">
          Client Build: {config.getBuild()}
        </div>
        <div className="flex-row-fixed build-text">
          Server Build: {serverbuild}
        </div>
      </Banners>
    </div>
  );
}
