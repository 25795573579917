import React, { useState } from "react";
//import PropTypes from "prop-types";

export default function DragDropList(props) {
  const [dropIndex, setDropIndex] = useState(-2);
  const [dragIndex, setDragIndex] = useState(-2);
  const [dragHeight, setDragHeight] = useState(20);
  const [selectedIndexFrom, setSelectedIndexFrom] = useState(0);
  const [selectedIndexTo, setSelectedIndexTo] = useState(0);

  const [lastClick, setLastClick] = useState(0);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [isCommandPressed, setIsCommandPressed] = useState(false);

  function onDrop(event, index) {
    if (props.moveItemsTo) {
      let dropItems = JSON.parse(event.dataTransfer.getData("text/plain"));
      props.moveItemsTo(dropItems, index + 1);
      setSelectedIndexFrom(selectedIndexFrom + index - dragIndex + 1);
      setSelectedIndexTo(selectedIndexTo + index - dragIndex + 1);
    }
  }

  function onDragOver(index) {
    setDropIndex(index);
  }

  function onDragEnd() {
    setDragIndex(-2);
    setDropIndex(-2);
  }

  function onSelect(from, to) {
    if (props.disabled) {
      return;
    }
    const count = props.children.length;
    if (from < 0 || to < 0 || from >= count || to >= count) {
      return;
    }

    setSelectedIndexFrom(from);
    setSelectedIndexTo(to);

    if (props.onSelect) {
      props.onSelect(from, to);
    }
  }

  function getSelectedItems() {
    let from = selectedIndexFrom;
    let to = selectedIndexTo;
    if (from > to) {
      [from, to] = [to, from];
    }
    return props.items.slice(from, to + 1);
  }

  function onExecute(index) {
    if (index === null) {
      if (props.onExecute) {
        props.onExecute(-1);
      }
      return;
    }

    if (index < 0 || index >= props.children.length) {
      return;
    }
    if (index === props.executeIndex) {
      return;
    }
    if (
      props.items &&
      props.items.length &&
      localStorage.getItem("playing_id") === props.items[selectedIndexFrom]._id
    ) {
      onSelect(index, index);
    }
    if (props.onExecute) {
      props.onExecute(index);
    }
  }

  function onEdit(index) {
    if (index === null || index < 0 || index >= props.children.length) {
      return;
    }

    if (props.onEdit) {
      props.onEdit(index);
    }
  }

  function renderDropTarget(index) {
    return (
      <div
        className="flex-column-fixed"
        onDrop={(event) => {
          event.preventDefault();
          onDrop(event, index);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          onDragOver(index);
        }}
        onDragEnd={() => {
          onDragEnd();
        }}
        style={{
          height: dropIndex === index ? String(dragHeight + "px") : "0px",
        }}
      >
        <hr />
      </div>
    );
  }

  function renderChildren(children, index) {
    let style = {
      overflow: "hidden",
    };
    if (index === dragIndex) {
      style = { ...style, opacity: "50%" };
    }

    return (
      <React.Fragment key={index}>
        <div
          className="flex-column-fixed"
          draggable={props.draggable}
          onDragStart={(event) => {
            setDragIndex(index);
            setDragHeight(event.target.clientHeight);
            let selectedItems = getSelectedItems();
            if (props.convertToDragItem) {
              selectedItems = selectedItems.map((item) =>
                props.convertToDragItem(item)
              );
            }
            event.dataTransfer.setData(
              "text/plain",
              JSON.stringify(selectedItems)
            );
          }}
          onDrop={
            props.droppable === false
              ? undefined
              : (event) => {
                  event.preventDefault();
                  onDrop(event, index);
                }
          }
          onDragOver={
            props.droppable === false
              ? undefined
              : (event) => {
                  event.preventDefault();
                  onDragOver(index);
                }
          }
          onDragEnd={() => {
            onDragEnd();
          }}
          onClick={(event) => {
            let now = Date.now();
            onSelect(event.shiftKey ? selectedIndexFrom : index, index);
            if (lastClick + 500 >= now) {
              setLastClick(now);
              if (index === props.executeIndex) {
                onExecute(null);
              } else {
                onExecute(index);
              }
            }
            setLastClick(now);
          }}
          style={style}
        >
          {children.map((child, index2) => (
            <div key={index + index2} className="flex-column-fixed">
              {child}
            </div>
          ))}
        </div>
        {renderDropTarget(index)}
      </React.Fragment>
    );
  }

  let childGroups = [];
  let childGroupsIndex = [];
  {
    let priorSelected = false;
    props.children.forEach((child, index) => {
      if (!priorSelected) {
        childGroups.push([child]);
        childGroupsIndex.push(index);
        if (index === selectedIndexFrom) {
          priorSelected = true;
        }
      } else if (index > selectedIndexTo) {
        priorSelected = false;
        childGroups.push([child]);
        childGroupsIndex.push(index);
      } else {
        childGroups[childGroups.length - 1].push(child);
      }
    });
  }

  let index = props.selectedIndexFrom;
  return (
    <div
      className="flex-column-fixed"
      style={{ opacity: props.disabled ? "50%" : "100%", width: "100%" }}
      tabIndex="0"
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          onExecute(index);
        } else if (event.keyCode === 16) {
          //shift
          setIsShiftPressed(props.multiSelect && true);
        } else if (event.keyCode === 91) {
          //command
          setIsCommandPressed(true);
        } else if (isCommandPressed && event.keyCode === 69) {
          //CMD+E
          onEdit(index);
        } else if (event.keyCode === 37) {
          //left arrow
          if (props.items) {
            let firstPlaying = -1;
            props.items.forEach((item, index) => {
              if (localStorage.getItem("playing_id") === item._id) {
                firstPlaying = index;
              }
            });
            onExecute(firstPlaying - 1);
          }
        } else if (event.keyCode === 38) {
          //up arrow
          if (isCommandPressed) {
            event.preventDefault();
            if (
              selectedIndexFrom >= 1 &&
              selectedIndexTo >= 1 &&
              props.moveItemsTo
            ) {
              props.moveItemsTo(
                getSelectedItems(),
                Math.min(selectedIndexFrom, selectedIndexTo) - 1
              );
              setSelectedIndexFrom(selectedIndexFrom - 1);
              setSelectedIndexTo(selectedIndexTo - 1);
            }
          } else if (isShiftPressed) {
            onSelect(selectedIndexFrom, selectedIndexTo - 1);
          } else {
            onSelect(selectedIndexFrom - 1, selectedIndexFrom - 1);
          }
        } else if (event.keyCode === 39) {
          //right arrow
          if (props.items) {
            let firstPlaying = -2;
            props.items.forEach((item, index) => {
              if (localStorage.getItem("playing_id") === item._id) {
                firstPlaying = index;
              }
            });
            onExecute(firstPlaying + 1);
          }
        } else if (event.keyCode === 40) {
          //down arrow
          if (isCommandPressed) {
            event.preventDefault();
            const dest = Math.max(selectedIndexFrom, selectedIndexTo) + 1;
            if (dest < props.items.length && props.moveItemsTo) {
              props.moveItemsTo(getSelectedItems(), dest + 1);
              setSelectedIndexFrom(selectedIndexFrom + 1);
              setSelectedIndexTo(selectedIndexTo + 1);
            }
          } else if (isShiftPressed) {
            onSelect(selectedIndexFrom, selectedIndexTo + 1);
          } else {
            onSelect(selectedIndexFrom + 1, selectedIndexFrom + 1);
          }
        } else if (event.keyCode === 66) {
          //B
          if (index === props.executeIndex) {
            onExecute(null);
          } else {
            onExecute(index);
          }
        } else if (event.keyCode === 8 || event.keyCode === 46) {
          //backspace / delete
          if (props.onDelete) {
            props.onDelete(getSelectedItems());
          }
        }
      }}
      onKeyUp={(event) => {
        if (event.keyCode === 16) {
          //shift
          setIsShiftPressed(false);
        } else if (event.keyCode === 91) {
          //command
          setIsCommandPressed(false);
        }
      }}
    >
      {renderDropTarget(-1)}
      {childGroups.map((children, index) =>
        renderChildren(children, childGroupsIndex[index])
      )}
    </div>
  );
}
