import React, { useState } from "react";
import "./Dropzone.css";

export default function Dropzone(props) {
  const [hightlight, setHightlight] = useState(false);
  const fileInputRef = React.createRef();

  function openFileDialog() {
    if (!props.dragOnly) {
      fileInputRef.current.click();
    }
  }

  function onFilesAdded(evt) {
    const files = evt.target.files;
    if (props.onFilesAdded) {
      const array = fileListToArray(files);
      props.onFilesAdded(array);
    }
  }

  function onDragOver(event) {
    event.preventDefault();
    if (props.disabed) return;
    setHightlight(true);
  }

  function onDragLeave(event) {
    setHightlight(false);
  }

  function onDrop(event) {
    event.preventDefault();
    if (props.disabed) return;
    const files = event.dataTransfer.files;
    if (props.onFilesAdded) {
      const array = fileListToArray(files);
      props.onFilesAdded(array);
    }
    setHightlight(false);
  }

  function fileListToArray(list) {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  return (
    <div
      className={
        (props.dragOnly ? "" : "Dropzone ") + (hightlight ? "Highlight" : "")
      }
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={openFileDialog}
      style={{ cursor: props.dragOnly ? "" : "pointer" }}
    >
      <input type="hidden" name="MAX_FILE_SIZE" value="1048576" />
      <input
        ref={fileInputRef}
        className="FileInput"
        type="file"
        multiple
        onChange={onFilesAdded}
      />
      {props.children || "Upload"}
    </div>
  );
}
