import { postUrl } from "./utils";

export function isElectron() {
  // Renderer process
  if (
    typeof window !== "undefined" &&
    typeof window.process === "object" &&
    window.process.type === "renderer"
  ) {
    return true;
  }

  // Main process
  if (
    typeof process !== "undefined" &&
    typeof process.versions === "object" &&
    !!process.versions.electron
  ) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  ) {
    return true;
  }

  return false;
}

class Electron {
  static BASE_URL = "http://localhost:8198";
  static API_URL = Electron.BASE_URL + "/api";

  static async newObject(className, options) {
    const response = await postUrl(
      Electron.API_URL + "/new/" + className,
      options
    );
    return response.data && response.data.id;
  }

  static async call(id, method, parameters) {
    const response = await postUrl(
      Electron.API_URL + "/call/" + id + "/" + method,
      parameters
    );
    return response.data;
  }

  static async getPropertyObject(id, property) {
    const response = await postUrl(
      Electron.API_URL + "/getpropertyobject/" + id + "/" + property
    );
    return response.data && response.data.id;
  }

  static async getObjectArray(id, method, parameters) {
    const response = await postUrl(
      Electron.API_URL + "/getobjectarray/" + id + "/" + method,
      parameters
    );
    return response.data;
  }
}

export class BrowserWindow {
  __id__ = undefined;
  __options__ = undefined;

  constructor(options) {
    if (options && options.__id__) {
      this.__id__ = options.__id__;
    } else {
      this.__options__ = options;
    }
  }

  async get__id__() {
    if (!this.__id__) {
      this.__id__ = await Electron.newObject("BrowserWindow", this.__options__);
    }
    return this.__id__;
  }

  async loadURL(url) {
    await Electron.call(await this.get__id__(), "loadURL", [url]);
  }

  async getWebContents() {
    const id = await Electron.getPropertyObject(
      await this.get__id__(),
      "webContents"
    );
    return new WebContents({ __id__: id });
  }

  static async getAllWindows() {
    let ids = await Electron.getObjectArray("BrowserWindow", "getAllWindows");
    return ids.map((id) => new BrowserWindow({ __id__: id }));
  }

  async close() {
    console.log("CLOSE");
    Electron.call(await this.get__id__(), "close");
  }

  async destroy() {
    console.log("DESTROY");
    Electron.call(await this.get__id__(), "destroy");
  }
}

export class WebContents {
  __id__ = undefined;

  constructor(options) {
    if (options && options.__id__) {
      this.__id__ = options.__id__;
    } else {
      throw new Error("unable to instantiate WebContents");
    }
  }

  async openDevTools() {
    await Electron.call(this.__id__, "openDevTools");
  }
}

export class screen {
  static async getAllDisplays() {
    return await Electron.call("screen", "getAllDisplays", []);
  }
}
