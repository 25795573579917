import React, { useState, useEffect } from "react";
import { getUrl, postUrl, deleteUrl } from "../utils/utils";
import Button from "react-bootstrap/Button";
import Banners from "./Banners";

export default function VideostreamingVm() {
  const [vms, setVms] = useState(null);
  const [passwordValue, setPasswordValue] = useState("");
  const [processingMessage, setProcessingMessage] = useState(null);

  useEffect(() => {
    loadVms();
  }, []);

  async function loadVms() {
    let response = await getUrl("/api/videostreaming_vm.php");
    if (response.status === 200) {
      setVms(response.data);
    }
  }

  function renderLoading() {
    return <h3>Checking for video streaming servers...</h3>;
  }

  function renderCreateVmUi() {
    return (
      <div className="flex-column-fixed">
        <h3>Allocated Video Streaming Servers:</h3>
        <p>None</p>
        <hr />
        <h3>Create Videostreaming Server</h3>
        Note: Creating a VM will result in charges (8 cents per hour or $51 per
        month). <b>Delete the created VM after each use.</b>
        <br />
        <div className="flex-row-fixed">
          Enter Password to create server:
          <input
            disabled={processingMessage !== null}
            type="password"
            name="password"
            value={passwordValue}
            onChange={({ currentTarget: input }) => {
              setPasswordValue(input.value);
            }}
          ></input>
          <Button
            variant="secondary"
            disabled={!passwordValue || processingMessage !== null}
            onClick={async () => {
              setProcessingMessage(
                "Creating Video Streaming server ... (takes 30 seconds or so)"
              );
              let response = await postUrl("/api/videostreaming_vm.php", {
                password: passwordValue,
              });
              if (response.data.error) {
                alert(response.data.error);
              }
              loadVms();
              setPasswordValue("");
              setProcessingMessage(null);
            }}
          >
            Create Videostreaming Server
          </Button>
        </div>
      </div>
    );
  }

  function renderVideoStreamingServers() {
    return (
      <div className="flex-column">
        <div className="flex-column-fixed">
          <h3>Allocated servers for video streaming</h3>

          <table border="1">
            <thead>
              <tr>
                <td>Name</td>
                <td>Created</td>
                <td>VM IP</td>
                <td>videostreaming.agolzer.com IP</td>
                <td>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {vms.map((vm) => (
                <tr>
                  <td>{vm.name}</td>
                  <td>{vm.created}</td>
                  <td>{vm.vm_ip}</td>
                  <td>{vm.videostreaming_agolzer_com_ip}</td>
                  <td>
                    <Button
                      variant="secondary"
                      disabled={processingMessage !== null}
                      onClick={async () => {
                        setProcessingMessage(
                          "Deleting video streaming server... (takes 30 seconds or so)"
                        );
                        await deleteUrl("/api/videostreaming_vm.php");

                        setPasswordValue("");
                        setProcessingMessage(null);
                        loadVms();
                      }}
                    >
                      Delete Videostreaming VM
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>&nbsp;</div>
      </div>
    );
  }

  return (
    <Banners
      breadcrumbs={[
        {
          name: "Videostreaming VM",
          link: `${process.env.PUBLIC_URL}/videostreamingvm`,
        },
      ]}
    >
      {" "}
      {vms === null
        ? renderLoading()
        : vms.length > 0
        ? renderVideoStreamingServers()
        : renderCreateVmUi()}
      {processingMessage !== null && <h2>{processingMessage}</h2>}
    </Banners>
  );
}
