import React from "react";

export default function SubtitlesSlide(props) {
  let width = props.width || "100%";
  let height = props.height || "100%";
  return (
    <svg width={width} height={height} viewBox="0 0 1920 1080">
      <rect
        x="0"
        y="0"
        width="1920"
        height="1080"
        stroke={props.green ? "#00FF00" : "#000000"}
        stroke-opacity="0"
        fill="#00FF00"
        fill-opacity={props.green ? "1" : "0"}
      />
      {[props.name].map((text) => {
        let ret = [];
        const fontSize = 32 * 2.8;
        for (let x = -3; x <= 3; x++) {
          for (let y = -3; y <= 3; y++) {
            ret.push(
              <text
                key={x + " " + y}
                x={1920 / 2 + x}
                y={900 + y}
                dominantBaseline="hanging"
                textAnchor="middle"
                fill="black"
                fontSize={fontSize}
                fontFamily="Vera"
                fontWeight="bold"
              >
                {text}
              </text>
            );
          }
        }
        ret.push(
          <text
            key="0"
            x={1920 / 2}
            y={900}
            dominantBaseline="hanging"
            textAnchor="middle"
            fill="#FFFFFF"
            fontSize={fontSize}
            fontFamily="Vera"
            fontWeight="bold"
          >
            {text}
          </text>
        );
        return ret;
      })}
    </svg>
  );
}
