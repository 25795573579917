import React, { Component } from "react";
import { getUrl, putUrl, deleteUrl } from "../utils/utils";
import lyricsUtils from "../utils/lyricsUtils";
import config from "../utils/config";
import authService from "../utils/authService";
import LyricsSlide from "./LyricsSlide";
import List from "./controls/List";
import Dialog from "./Dialog";
import { v4 as uuidv4 } from "uuid";

export function getLyricsItemsFromContent(content) {
  let lyrics = lyricsUtils.getSlideData(content);
  if (!lyrics) {
    return [];
  }

  let ret = [];
  let verse = 0;
  lyrics.forEach((lyric, index) => {
    let name = undefined;
    if (lyric.type === "Verse") {
      if (lyric.Title && lyric.Title.text && lyric.Title.text[0]) {
        name = lyric.Title.text[0];
      } else {
        if (!lyric.isSplit) {
          verse++;
        }
        name = "Verse " + verse;
      }
    } else {
      if (lyric.Title && lyric.Title.text && lyric.Title.text[0]) {
        name =
          (lyric.type && lyric.type.substring(0, 1)) +
          ": " +
          lyric.Title.text[0];
      } else {
        name = lyric.type;
      }
    }
    if (lyric.isSplit) {
      name += " (Cont.)";
    }
    if (
      index > 0 &&
      lyric.Subtitle &&
      lyric.Subtitle.text &&
      lyric.Subtitle.text[0]
    ) {
      lyric = { ...lyric };
      lyric.Subtitle = { ...lyric.Subtitle };
      lyric.Subtitle.text = [""];
    }
    ret.push({
      type: "lyrics",
      lyric,
      _id: uuidv4(),
      name,
      isChild: index > 0,
    });
  });
  return ret;
}

export default class Lyrics extends Component {
  state = {
    search: "",
    files: [],
    selectedFiles: [],
    content: "",
    id: undefined,
    sldieData: undefined,
    edit: false,
  };

  searchLyrics = (search) => {
    let searchLower = search.toLowerCase();
    let selectedFiles = [];
    this.state.files.forEach((file, index) => {
      let id = file._id;
      let content = file.content;
      if (content.indexOf("#Copyright") !== -1) {
        content = content.substring(0, content.indexOf("#Copyright"));
      }
      if (id.endsWith(".txt")) {
        id = id.substring(0, id.length - ".txt".length);
      }
      if (
        id.toLowerCase().indexOf(searchLower) !== -1 ||
        content.toLowerCase().indexOf(searchLower) !== -1
      ) {
        selectedFiles.push({ ...file, name: id });
      }
    });
    this.setState({ search, selectedFiles });
  };

  async componentDidMount() {
    await this.loadLyrics();
  }

  loadLyrics = async () => {
    let ids = [];
    if (authService.getProfile()) {
      ids = [authService.getProfile().token];
    }
    ids = [...ids, ...config.getAdditionalLyrics()];
    if (ids.length === 2 && ids[0] === ids[1]) {
      ids = [ids[0]];
    }
    let filesPromises = ids.map(async (id) => {
      try {
        let response = await getUrl("/api/lyrics/" + id);
        if (response.status === 200) {
          return response.data;
        } else {
          return [];
        }
      } catch (e) {
        //skip missing users
        return [];
      }
    });
    let files = [];
    (await Promise.all(filesPromises)).forEach((lyrics, index) => {
      if (lyrics && index > 0) {
        lyrics.forEach((lyric) => {
          files.push({
            ...lyric,
            _id: "*" + lyric._id,
          });
        });
      } else if (lyrics) {
        lyrics.forEach((lyric) => {
          files.push({
            ...lyric,
          });
        });
      }
    });

    this.setState(
      {
        files,
      },
      () => {
        this.searchLyrics(this.state.search);
      }
    );
  };

  renderLyricsList() {
    if (this.state.selectedFiles.length === 0) {
      return (
        <List>
          <div></div>
          <div
            key="empty"
            style={{
              flex: "0 0 auto",
              backgroundColor: "#FFFFFF",
            }}
          >
            No entries found
          </div>
        </List>
      );
    }
    return (
      <List
        disabled={this.state.edit}
        multiSelect={false}
        onSelect={(selectedIndex, from, to) => {
          if (
            selectedIndex >= 0 &&
            selectedIndex < this.state.selectedFiles.length
          ) {
            let file = this.state.selectedFiles[selectedIndex];
            let slideData = lyricsUtils.getSlideData(file.content);
            let files = [...this.state.files];
            for (let i = 0; i < files.length; i++) {
              files[i].isSelected =
                files[i]._id === this.state.selectedFiles[selectedIndex]._id;
            }
            let selectedFiles = [...this.state.selectedFiles];
            for (let i = 0; i < selectedFiles.length; i++) {
              selectedFiles[i].isSelected = i === selectedIndex;
            }
            this.setState({
              content: file.content,
              slideData,
              id: file._id,
              files,
              selectedFiles,
            });
          }
        }}
        onExecute={() => {
          if (this.props.onAdd) {
            this.props.onAdd(this.state.content);
          }
        }}
      >
        {this.state.selectedFiles.map((file) => (
          <div
            draggable={true}
            onDragStart={(event) => {
              let items = getLyricsItemsFromContent(this.state.content);
              event.dataTransfer.setData("text/plain", JSON.stringify(items));
            }}
            key={file._id}
            style={{
              flex: "0 0 auto",
              backgroundColor: file.isSelected ? "#CCCCCC" : "#FFFFFF",
            }}
          >
            {file.name}
          </div>
        ))}
      </List>
    );
  }

  renderEditButtons() {
    return (
      <React.Fragment>
        <button
          onClick={async () => {
            try {
              await putUrl(
                "/api/lyrics/" +
                  authService.getProfile().token +
                  "/" +
                  encodeURIComponent(this.state.id),
                { content: this.state.content }
              );
              let files = [...this.state.files];
              let file = this.state.files.find(
                (file) => file._id === this.state.id
              );
              file.content = this.state.content;
              file = this.state.selectedFiles.find(
                (file) => file._id === this.state.id
              );
              if (file) {
                file.content = this.state.content;
              }
              this.setState({ edit: false, files });
            } catch (error) {
              alert("Save failed: " + error);
            }
          }}
        >
          Save
        </button>
        <button
          onClick={() => {
            this.setState({ edit: false });
          }}
        >
          Cancel
        </button>
        <button
          onClick={async () => {
            if (
              (await Dialog.showConfirm(
                "Deleting Lyrics",
                "Do you really want to delete " + this.state.id + "?"
              )) === "Yes"
            ) {
              try {
                await deleteUrl(
                  "/api/lyrics/" +
                    authService.getProfile().token +
                    "/" +
                    encodeURIComponent(this.state.id)
                );
                this.setState(
                  {
                    edit: false,
                    content: "",
                    id: undefined,
                    search: "",
                  },
                  () => {
                    this.loadLyrics();
                  }
                );
              } catch (error) {
                alert("Delete failed: " + error);
              }
            }
          }}
        >
          Delete
        </button>
      </React.Fragment>
    );
  }

  renderReadButtons() {
    return (
      <React.Fragment>
        <button
          onClick={async () => {
            let filename = await Dialog.showPrompt(
              "Enter Lyrics Title",
              "Enter Lyrics Title",
              this.state.search
            );
            if (filename) {
              filename = filename.trim();
            }
            if (filename) {
              let content = "#Title " + filename + "\n";
              try {
                await putUrl(
                  "/api/lyrics/" +
                    authService.getProfile().token +
                    "/" +
                    encodeURIComponent(filename),
                  { content }
                );
                this.setState(
                  {
                    edit: true,
                    content,
                    id: filename,
                    search: filename,
                  },
                  () => {
                    this.loadLyrics();
                  }
                );
              } catch (error) {
                alert("Save failed: " + error);
              }
            }
          }}
        >
          New
        </button>
        <button
          disabled={
            this.state.id === undefined || this.state.id.startsWith("*")
          }
          onClick={() => {
            this.setState({ edit: true });
          }}
        >
          Edit
        </button>
        {this.props.onAdd && (
          <button
            disabled={this.state.id === undefined}
            onClick={async () => {
              this.props.onAdd(this.state.content);
            }}
          >
            Add to Playlist
          </button>
        )}
      </React.Fragment>
    );
  }

  renderSearchAndButtons() {
    return (
      <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "row" }}>
        <input
          disabled={this.state.edit}
          name="search"
          value={this.state.search}
          onChange={({ currentTarget: input }) => {
            this.searchLyrics(input.value);
          }}
          placeholder="Search"
          style={{ flex: "1 1 auto" }}
        />

        {!this.props.readonly &&
          (this.state.edit
            ? this.renderEditButtons()
            : this.renderReadButtons())}
      </div>
    );
  }

  renderLyricsTextbox() {
    return (
      <textarea
        disabled={!this.state.edit}
        value={this.state.content}
        onChange={({ currentTarget: input }) => {
          this.setState({
            content: input.value,
            slideData: lyricsUtils.getSlideData(input.value),
          });
        }}
        style={{ width: "100%", height: "100%" }}
      />
    );
  }

  render() {
    let slides = lyricsUtils.getSlideData(this.state.content);
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
          overflow: "auto",
        }}
      >
        {this.renderSearchAndButtons()}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1 0 30%",
            overflowY: "auto",
            alignItems: "stretch",
          }}
        >
          {this.renderLyricsList()}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1 70%",
            }}
          >
            <div
              style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                alignItems: "stretch",
              }}
            >
              <div style={{ flex: "1 1 50%" }}>
                {this.renderLyricsTextbox()}
              </div>
              <div style={{ flex: "1 1 50%", overflowY: "auto" }}>
                {slides.map((slide, index) => (
                  <div key={index} style={{ margin: "3px" }}>
                    <LyricsSlide
                      slide={slide}
                      width="100%"
                      height="100%"
                      posterOnly={index !== 0}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
