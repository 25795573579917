/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer, 2019-2021
 */

import React, { Component } from "react";
import authService from "../utils/authService";
import Banners from "./Banners";

export default class LoginForm extends Component {
  state = {
    data: {
      host: "https://siteworship.agolzer.com",
      websocket: "http://localhost:8199",
      email: "",
      password: "",
      password2: "",
    },
    error: null,
    form: "login", // "resetPasswordRequest", "resetPasswordLinkSubmitted", "register", "changePassword", "passwordChanged"
  };

  getUrlParam(param) {
    var ret = undefined;
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        if (key === param) {
          ret = decodeURIComponent(value);
        }
      }
    );
    return ret;
  }

  async componentDidMount() {
    let sessionid = this.getUrlParam("sessionid");
    let redirect = this.getUrlParam("redirect");
    let reset = this.getUrlParam("reset");
    if (reset !== undefined) {
      this.setState({ form: "changePassword" });
    }
    let email = this.getUrlParam("email");
    if (email !== undefined) {
      let data = {
        ...this.state.data,
      };
      data.email = email;
      this.setState({ data });
      if (authService.getEmail()) {
        if (authService.getEmail() === email) {
          window.location.href = redirect ? redirect : "/";
        } else {
          authService.logout();
          window.location.href =
            "/login?email=" +
            encodeURIComponent(email) +
            "&redirect=" +
            encodeURIComponent(redirect ? redirect : "/");
        }
      }
    }
    if (this.getUrlParam("sessionid")) {
      let error = await authService.loginSession(sessionid);
      if (error) {
        this.setState({ error });
      } else if (redirect) {
        window.location.href = redirect;
      } else {
        window.location.href =
          "/settings/users/" + authService.getProfile().token;
      }
    }
  }

  INVALID_EMAIL_PASSWORD =
    "invalid email-address or password. Please try again.";

  async doSubmit() {
    let error = await authService.login(
      this.state.data.host,
      this.state.data.websocket,
      this.state.data.email,
      this.state.data.password
    );
    if (error) {
      this.setState({ error });
    } else if (authService.getUsername() === undefined) {
      this.setState({ error: this.INVALID_EMAIL_PASSWORD });
    } else {
      let publicUrl = `${process.env.PUBLIC_URL}`;
      let redirect = this.getUrlParam("redirect");
      if (redirect === undefined) {
        window.location = publicUrl + "/";
      } else {
        window.location = redirect;
      }
    }
  }

  handleChange = ({ currentTarget: input }) => {
    const data = {
      ...this.state.data,
    };
    data[input.name] = input.value;
    this.setState({ data, error: "" });
  };

  render() {
    return (
      <Banners breadcrumbs={[]}>
        <div
          style={{
            maxWidth: "540px",
            paddingRight: "15px",
            paddingLeft: "15px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "15px",
          }}
        >
          <h3>Sign in</h3>
          <br />
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label for="email">E-Mail</label>
              <input
                className="form-control d-print-none"
                type="email"
                key="email"
                name="email"
                value={this.state.data.email}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label for="password">Password</label>
              <input
                className="form-control d-print-none"
                type="password"
                key="password"
                name="password"
                value={this.state.data.password}
                onChange={this.handleChange}
              />
            </div>
            {this.state.error && (
              <div className="alert alert-danger">{this.state.error} </div>
            )}
            <br />
            <button
              onClick={async (e) => {
                e.preventDefault();
                this.doSubmit();
              }}
              className="btn btn-primary btn-space"
            >
              Login
            </button>
          </form>
        </div>
      </Banners>
    );
  }
}
