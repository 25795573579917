import React from "react";
import "./Progress.css";

export default function Progress({ progress }) {
  return (
    <div className="ProgressBar">
      <div className="Progress" style={{ width: progress + "%" }} />
    </div>
  );
}
