function getDefaultTextInfo() {
  return {
    Title: {
      FontName: "Arial",
      FontSize: 79,
      FontStyle: "bold,italic",
      FontColor: "White",
      X: 5,
      Y: 400,
      Width: 1909,
      Height: 281,
      Shadow: 4,
      text: [],
    },
    Subtitle: {
      text: [],
    },
    Body: {
      FontName: "Arial",
      FontSize: 60,
      FontStyle: "bold",
      FontColor: "White",
      X: 5,
      Y: 75,
      Width: 1909,
      Height: 1005,
      Shadow: 4,
      text: [],
    },
    Copyright: {
      FontName: "Arial",
      FontSize: 20,
      FontColor: "White",
      X: 5,
      Y: 919,
      Width: 1909,
      Height: 161,
      Shadow: 2,
      text: [],
    },
  };
}

function getSlideData(data) {
  //s_align = StringAlignmentCenter;
  let isCopyright = false;

  let slideLyrics = [];
  let chorusSlides = [];

  let type = "Verse";
  let background = new Date().getTime();
  let slide = {
    ...getDefaultTextInfo(),
    type,
    isSplit: false,
    background: background,
    width: 1024,
    height: 768,
  };
  let align = { Title: "center", Body: "center", Copyright: "center" };
  let lines = data.split("\n");
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].endsWith("\r")) {
      lines[i] = lines[i].substring(0, lines[i].length - 1);
    }
  }

  lines.forEach((line) => {
    line = line.trim();
    if (isCopyright) {
      line.split("//").forEach((singleLine) => {
        slide.Copyright.text.push(singleLine);
      });
    } else if (line.startsWith("#Title ")) {
      let s = { ...slide };
      s.Title = { ...slide.Title };
      s.Title.text = [...slide.Title.text];
      s.Body = { ...slide.Body };
      s.Body.text = [...slide.Body.text];
      s.Copyright = { ...slide.Copyright };
      s.Copyright.text = [...slide.Copyright.text];
      line
        .substring("#Title ".length)
        .split("//")
        .forEach((singleLine) => {
          s.Title.text.push(singleLine);
        });
      slideLyrics.push(s);
    } else if (line.startsWith("#Subtitle ")) {
      line
        .substring("#Subtitle ".length)
        .split("//")
        .forEach((singleLine) => {
          slide.Subtitle.text.push(singleLine);
        });
    } else if (line.startsWith("#Chorus")) {
      if (chorusSlides.length === 0) {
        slide.type = "Chorus";
        chorusSlides.push(slide);
      } else {
        chorusSlides.forEach((chorusSlide) => {
          let s = { ...chorusSlide };
          s.Title = getDefaultTextInfo().Title;
          slideLyrics.push(s);
        });
      }
    } else if (line.startsWith("#Copyright")) {
      isCopyright = true;
    } else if (line.startsWith("#Repeat")) {
      let lastSlide = slideLyrics.length - 1;
      if (lastSlide >= 0) {
        let startSlide = lastSlide;
        while (startSlide > 0 && slideLyrics[startSlide].isSplit) {
          startSlide--;
        }
        for (let i = startSlide; i <= lastSlide; i++) {
          let sd = { ...slideLyrics[i] };
          sd.Title = getDefaultTextInfo().Title;
          sd.type = "Repeat";
          slideLyrics.push(sd);
        }
      }
    } else if (line.startsWith("#Ending")) {
      slide.type = "Ending";
    } else if (line.startsWith("#Bridge")) {
      slide.type = "Bridge";
    } else if (line.startsWith("#Align ")) {
      //left, right, center
      align.Body = line.substring("#Align ".length);
      slide.Body.align = align.Body;
    } else if (line.length === 0 || line.startsWith("#Split")) {
      if (
        slide.Title.text.length ||
        slide.Subtitle.text.length ||
        slide.Body.text.length ||
        slide.Copyright.text.length
      ) {
        if (
          slide.Copyright.length &&
          !slide.Title.length &&
          !slide.Subtitle.length &&
          !slide.Body.length &&
          slideLyrics.length > 0
        ) {
          //only copyright - add to previous slide
          let last = slideLyrics[slideLyrics.length - 1];
          last.Copyright = { ...slide.Copyright };
        } else {
          slideLyrics.push(slide);
          if (line.startsWith("#Split")) {
            slide = { type: slide.type, isSplit: true };
          } else {
            slide = { type: "Verse", isSplit: false };
          }
          slide = {
            ...slide,
            ...getDefaultTextInfo(),
            background: background,
            width: slide.width,
            height: slide.height,
          };
          if (slide.type === "Chorus") {
            chorusSlides.push(slide);
          }
        }
      }
    } else if (line.startsWith("#BodyRECT ")) {
      let cols = line.split(" ");
      if (cols.length === 5 || cols.length === 6) {
        let ti = slide.Body;
        ti.x = parseInt(cols[1]);
        ti.y = parseInt(cols[2]);
        ti.width = parseInt(cols[3]);
        ti.height = parseInt(cols[4]);
        if (cols.length === 6) {
          ti.shadow = parseInt(cols[5]);
        }
      }
    } else if (line.startsWith("#WidthHeight ")) {
      let cols = line.split(" ");
      if (cols.length === 3) {
        slide.width = parseInt(cols[1]);
        if (isNaN(slide.width)) slide.width = 1024;
        slide.height = parseInt(cols[2]);
        if (isNaN(slide.height)) slide.height = 768;
      }
    } else if (line.startsWith("#Background ")) {
      let background = line.substring("#Background ".length);
      if (background === "none") {
        slide.background = null;
      } else {
        slide.background = background;
      }
    } else if (line.startsWith("#FontSize ")) {
      let size = parseInt(line.substring("#FontSize ".length));
      if (isNaN(size)) {
        size = slide.Body.fontSize;
      }
      slide.Body.fontSize = size;
    } else if (line.startsWith("#FontStyle ")) {
      slide.fontStyle = line.substring("#FontStyle ".length);
    } else {
      line.split("//").forEach((singleLine) => {
        slide.Body.text.push(singleLine);
      });
    }
  });

  if (
    !slide.Title.text.length &&
    !slide.Subtitle.text.length &&
    !slide.Body.text.length &&
    slide.Copyright.text.length
  ) {
    if (slideLyrics.length > 0) {
      slideLyrics[slideLyrics.length - 1].Copyright = slide.Copyright;
    } else {
      slideLyrics.push(slide);
    }
  } else if (
    slide.Title.text.length ||
    slide.Subtitle.text.length ||
    slide.Body.text.length ||
    slide.Copyright.text.length
  ) {
    slideLyrics.push(slide);
  }

  let verse = 0;
  slideLyrics.forEach((sd) => {
    if (sd.type === "Verse" && sd.Body.text.length > 0) {
      if (!sd.isSplit) {
        verse++;
      }
      sd.Verse = verse;
    } else {
      sd.Verse = undefined;
    }
  });

  return slideLyrics;
}

const lyricsUtils = {
  getSlideData,
};

export default lyricsUtils;
