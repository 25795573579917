import axios from "axios";
import { isElectron } from "./electron";

function getFullUrl(url) {
  if (
    url.toLowerCase().startsWith("https://") ||
    url.toLowerCase().startsWith("http://")
  ) {
    return url;
  } else {
    return "https://siteworship.agolzer.com" + url;
  }
}

export async function getUrl(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(getFullUrl(url))
      .then((res) => resolve(res))
      .catch((exc) => {
        if (isElectron() && url.startsWith("/")) {
          axios
            .get("http://localhost:8199" + url)
            .then((res) => {
              resolve(res);
            })
            .catch((rej2) => reject(rej2));
        } else {
          reject(exc);
        }
      });
  });
}

export async function deleteUrl(url) {
  return axios.delete(getFullUrl(url));
}

export async function patchUrl(url, body) {
  return axios.patch(getFullUrl(url), body);
}

export async function putUrl(url, data) {
  return axios.put(getFullUrl(url), data);
}

export async function postUrl(url, data) {
  return axios.post(getFullUrl(url), data);
}
