import React, { useState } from "react";
import "../resources/fonts/vera/fonts.css";
import WebSocket from "./WebSocket";

export default function Overlay(props) {
  const [url, setUrl] = useState("");

  return (
    <React.Fragment>
      <div className="d-print-none">&nbsp;</div>
      <div className="flex-row-fixed d-print-none">
        <input
          type="text"
          name="url"
          placeholder="enter URL to overlay"
          onChange={({ currentTarget: input }) => {
            setUrl(input.value);
          }}
        />
        <button
          className="btn btn-secondary"
          onClick={() => {
            WebSocket.emit("overlay", { url: url }, props.playlistitems);
          }}
        >
          Overlay Webpage
        </button>
      </div>
    </React.Fragment>
  );
}
