import React from "react";
import Tab from "./Tab";

export default function Tabs({
  children,
  activeTab,
  onClickTabItem,
  readonly,
}) {
  return (
    <React.Fragment>
      <ol className="tab-list d-print-none" style={{ flex: "0 0 auto" }}>
        <select
          className="d-sm-none"
          onChange={({ currentTarget: input }) => onClickTabItem(input.value)}
          value={activeTab}
        >
          {children
            .filter((c) => !readonly || c.props.label === activeTab)
            .map((child) => {
              const { label } = child.props;

              return (
                <option key={label} id={label}>
                  {label}
                </option>
              );
            })}
        </select>
        {children
          .filter((c) => !readonly || c.props.label === activeTab)
          .map((child) => {
            const { label, display } = child.props;

            return (
              <Tab
                display={display}
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
      </ol>
      <div
        className="tab-content"
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        {children.map((child, index) => {
          return (
            <div
              key={index}
              style={{
                flex: "1 1 auto",
                flexDirection: "column",
                overflowY: "auto",
                display: child.props.label !== activeTab ? "none" : "flex",
              }}
            >
              {child.props.children}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}
